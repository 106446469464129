import ReactTooltip from 'react-tooltip'
import { useRef } from 'react'

export default function useTooltip() {
    const elRef = useRef(null)
    return {
        ref: elRef,
        props: {
            onMouseEnter() {
                ReactTooltip.show(elRef.current)
            },
            onMouseLeave() {
                ReactTooltip.hide(elRef.current)
            },
        },
    }
}
